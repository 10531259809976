import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChangeEvent, ReactElement, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'relay-hooks'
import { useQuery } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'
import { AvatarPopupDeleteAvatarMutation } from '../../generated/AvatarPopupDeleteAvatarMutation.graphql'
import { AvatarPopupUserQuery } from '../../generated/AvatarPopupUserQuery.graphql'

import { useStores } from '../../stores'
import { toDataUrl } from '../../utils/toDataUrl'
import { Avatar, AvatarContext } from '../common/Avatar'

import { PrimaryButton } from '../common/PrimaryButton'
import { SecondaryButton } from '../common/SecondaryButton'

import styles from './AvatarPopup.scss'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'

export function AvatarPopup(): ReactElement {
  const { commonStore } = useStores()
  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement>(null)

  const deleteButton = useRef<HTMLButtonElement>(null)

  const [deleteAvatar, deleteAvatarResult] =
    useMutation<AvatarPopupDeleteAvatarMutation>(
      graphql`
        mutation AvatarPopupDeleteAvatarMutation {
          changeAvatar(file: null) {
            id
            profileImage
          }
        }
      `,
      { variables: {} }
    )
  const user = useQuery<AvatarPopupUserQuery>(graphql`
    query AvatarPopupUserQuery {
      me {
        id
        fullName
        profileImage
      }
    }
  `)

  const onInputEnterKey = useEnterKeyHandler(
    useCallback(() => {
      if (inputRef !== null && inputRef.current) {
        inputRef.current.click()
      }
    }, [])
  )

  const onFileChosen = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (
        !event.currentTarget.files ||
        event.currentTarget.files.length === 0
      ) {
        return
      }

      const file = event.currentTarget.files[0]
      toDataUrl(file).then((image) =>
        commonStore.openPopup(
          {
            image,
            mime: file.type,
            filename: file.name,
            type: 'crop-avatar',
          },
          true
        )
      )
    },
    [commonStore]
  )

  const doDeleteAvatar = useCallback(() => {
    return new Promise<void>((resolve) => {
      deleteAvatar().finally(commonStore.closePopup)
      resolve()
    })
  }, [commonStore, deleteAvatar])

  const onDeleteClicked = useCallback(() => {
    commonStore.openPopup(
      {
        type: 'confirm-yes-no',
        onClose: () => {
          commonStore.closePopup()
        },
        onConfirm: () => doDeleteAvatar(),
        title: t('popup.confirm.deleteAvatarTitle'),
        message: t('popup.confirm.deleteAvatarMessage'),
        confirmButtonText: t('popup.confirm.deleteAvatar'),
        cancelButtonText: t('popup.confirm.cancel'),
        backButton: true,
      },
      true
    )
  }, [commonStore, doDeleteAvatar, t])

  return (
    <div className={styles.content}>
      <h1 id='popup-label'>{t('profile.profilePictureTitle')}</h1>
      <p>{t('profile.pictureEdit')}</p>

      {user.data?.me && (
        <Avatar
          filename={user.data.me.profileImage}
          name={user.data.me.fullName}
          userId={user.data.me.id}
          context={AvatarContext.default}
        />
      )}

      <div className={styles.buttons}>
        <PrimaryButton
          component='label'
          role='button'
          onKeyDown={onInputEnterKey}
          tabIndex={0}
          icon
          aria-label={t('profile.pictureChoose')}
        >
          <input
            ref={inputRef}
            className={styles.input}
            type='file'
            accept='image/*'
            onChange={onFileChosen}
          />

          <FontAwesomeIcon icon={faImage} />
        </PrimaryButton>

        {user.data?.me && user.data?.me.profileImage && (
          <SecondaryButton
            icon
            disabled={deleteAvatarResult.loading}
            onClick={onDeleteClicked}
            componentRef={deleteButton}
            aria-label={t('profile.pictureDelete')}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </SecondaryButton>
        )}
      </div>
    </div>
  )
}
