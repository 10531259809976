import { ComponentProps, ReactElement, useCallback } from 'react'

import { PrimaryButton } from './PrimaryButton'
import { SecondaryButton } from './SecondaryButton'
import { useStores } from '../../stores'
import { ConfirmYesNoPopup as ConfirmYesNoPopupProps } from '../../stores/commonStore'

import styles from './ConfirmYesNoPopup.scss'
import { TertiaryButton } from './TertiaryButton'
import { BackIcon } from './BackIcon'

interface OwnProps {
  setAttachment(
    children: ReactElement,
    props?: Partial<ComponentProps<typeof TertiaryButton>>
  ): void
}

export function ConfirmYesNoPopup(
  props: ConfirmYesNoPopupProps & OwnProps
): ReactElement {
  const { commonStore } = useStores()

  if (props.backButton) {
    props.setAttachment(<BackIcon />)
  }

  const confirm = useCallback((): void => {
    props.onConfirm().then(() => {
      ;(props.onClose ?? commonStore.closePopup)()
    })
  }, [commonStore, props])

  return (
    <div className={styles.content}>
      <h1 id='popup-label'>{props.title}</h1>
      <div
        className={styles.body}
        dangerouslySetInnerHTML={{ __html: props.message }}
      />
      <PrimaryButton className={styles.button} onClick={confirm}>
        {props.confirmButtonText}
      </PrimaryButton>
      <SecondaryButton
        className={styles.button}
        onClick={props.onClose ?? commonStore.closePopup}
      >
        {props.cancelButtonText}
      </SecondaryButton>
    </div>
  )
}
