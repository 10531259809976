import i18n from 'i18next'
import cookies from 'js-cookie'
import { action, IObservableArray, makeObservable, observable } from 'mobx'
import { Key, SyntheticEvent } from 'react'
import { isLanguageSupported } from '../../locales/detectLanguage'
import { DuelFinalizingPopup_item$key } from '../generated/DuelFinalizingPopup_item.graphql'
import { DuelRequestPopup_item$key } from '../generated/DuelRequestPopup_item.graphql'
import { PlayDuelRound_duel$key } from '../generated/PlayDuelRound_duel.graphql'

import { WebService } from '../services/WebService'

export type AboutDuelsPopup = { type: 'about-duels' }
export type AvatarCropPopup = {
  type: 'crop-avatar'
  image: string
  mime: string
  filename: string
}
export type AvatarPopup = { type: 'avatar' }
export type ComeBackLaterPopup = {
  type: 'come-back-later'
  comeBackDate: string
  onConfirm(): Promise<void>
}
export type ConfirmStopPracticingPopup = {
  type: 'confirm-stop-practicing'
  callback: (ok: boolean) => void
}
export type ConfirmYesNoPopup = {
  type: 'confirm-yes-no'
  title: string
  message: string
  confirmButtonText: string
  cancelButtonText: string
  onClose?: () => void
  onConfirm(): Promise<void>
  backButton?: boolean
}
export type DuellingNotAvailablePopup = {
  type: 'duelling-not-available'
}
export type DuelRequestPopup = {
  type: 'duel-request'
  item: DuelRequestPopup_item$key
  duelsConnectionIds: string[]
  duelRequestsConnectionIds: string[]
  onClose?: () => void
  popupKey: Key
}
export type FinalizingDuelPopup = {
  type: 'duel-finalizing'
  duelsConnectionIds: string[]
  item: DuelFinalizingPopup_item$key
  onClose?: () => void
  popupKey: Key
}
export type FinishedOnboardingPopup = {
  type: 'finished-onboarding'
  allowContinueToPowerApp: boolean
  continueToPowerApp: (event: SyntheticEvent) => void
  stayInOnfire: () => void
}
export type InternetRequiredPopup = { type: 'internet-required' }
export type MaintenanceModePopup = {
  type: 'maintenance'
}
export type NewDuelPopup = {
  type: 'new-duel'
  duelsConnectionIds: string[]
  duelRequestsConnectionIds: string[]
  onClose?: () => void
  slotsAvailable: number
}
export type NewVersionPopup = { type: 'new-version' }
export type PlayDuelRound = {
  type: 'play-duel-round'
  duel: PlayDuelRound_duel$key
  onClose?: () => void
  onPlay(): void
}
export type PrivacyPolicyPopup = {
  type: 'privacy-policy'
}

export type RepetitionStateInfoPopup = {
  type: 'repetition-state-info'
}

export type Popup =
  | AboutDuelsPopup
  | AvatarCropPopup
  | AvatarPopup
  | ComeBackLaterPopup
  | ConfirmStopPracticingPopup
  | ConfirmYesNoPopup
  | DuelRequestPopup
  | DuellingNotAvailablePopup
  | FinalizingDuelPopup
  | FinishedOnboardingPopup
  | InternetRequiredPopup
  | MaintenanceModePopup
  | NewDuelPopup
  | NewVersionPopup
  | PlayDuelRound
  | PrivacyPolicyPopup
  | RepetitionStateInfoPopup

export class CommonStore {
  private static readonly INTENDED_URL_COOKIE_NAME = 'commonStore:intendedUrl'

  @observable
  public appInitialized: boolean

  @observable
  public language: string

  @observable
  public itemNavigationBarShown: boolean

  @observable
  public navBarHidden: boolean

  @observable
  public online: boolean

  @observable
  public popups: IObservableArray<Popup>

  @observable
  public localPopupShown: boolean

  @observable
  public postAuthRedirect?: string

  public webservice: WebService

  public constructor(webservice: WebService) {
    this.webservice = webservice
    this.webservice.commonStore = this

    this.appInitialized = false
    this.itemNavigationBarShown = false
    this.language = 'en-US'
    this.navBarHidden = false
    this.online = true
    this.popups = observable([])
    this.localPopupShown = false

    const intendedUrl = cookies.get(CommonStore.INTENDED_URL_COOKIE_NAME)
    if (intendedUrl) {
      this.postAuthRedirect = intendedUrl
    }

    makeObservable(this)
  }

  @action
  public setAppInitialized(flag: boolean): void {
    this.appInitialized = flag
  }

  @action
  public closePopup = (): void => {
    this.popups.shift()
  }

  public hasPopup<T extends Popup>(
    type: T['type'],
    filter?: (popup: T) => boolean
  ): boolean {
    const matchingPopups: T[] = this.popups.filter(
      (popup) => popup.type === type
    ) as T[]

    if (filter) {
      return matchingPopups.filter(filter).length > 0
    }

    return matchingPopups.length > 0
  }

  @action
  public replacePopup(popup: Popup): void {
    this.popups[0] = popup
  }

  @action
  public async setLanguage(language: string): Promise<void> {
    if (!isLanguageSupported(language)) {
      return
    }

    this.language = language

    await i18n.changeLanguage(language)

    document.documentElement.setAttribute('dir', i18n.dir(language))
    document.documentElement.setAttribute('lang', language)
  }

  @action
  public setItemNavigationBarShown(shown: boolean): void {
    this.itemNavigationBarShown = shown
  }

  @action
  public setNavBarHidden(hidden: boolean): void {
    this.navBarHidden = hidden
  }

  @action
  public setOnline(online: boolean): void {
    this.online = online
  }

  @action
  public setPostAuthRedirect(url?: string): void {
    this.postAuthRedirect = url

    if (url) {
      cookies.set(CommonStore.INTENDED_URL_COOKIE_NAME, url)
    } else {
      cookies.remove(CommonStore.INTENDED_URL_COOKIE_NAME)
    }
  }

  @action
  public openPopup(popup: Popup, insertInFront = false): void {
    if (insertInFront) {
      this.popups.spliceWithArray(0, 0, [popup])
    } else {
      this.popups.push(popup)
    }
  }

  @action
  public setLocalPopupShown(show = true) {
    this.localPopupShown = show
  }

  static canShowPopup(
    popup: Popup,
    user: Record<string, unknown> | undefined | null
  ): boolean {
    // All popups can be shown if the user is logged in, otherwise only show
    // maintenance mode popups.
    return !!user || popup.type === 'maintenance'
  }
}
