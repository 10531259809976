import { observer } from 'mobx-react'
import { lazy, ReactElement, Suspense, useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { BrowserRouter as Router, Navigate, useRoutes } from 'react-router-dom'
import { useFragment } from 'relay-hooks'
import { useQuery } from 'relay-hooks/lib'
import { graphql } from 'relay-runtime'
import { useEnvironment } from '../../App'
import { RoutingAppRoutes_user$key } from '../../generated/RoutingAppRoutes_user.graphql'
import { RoutingOnlyGuests_user$key } from '../../generated/RoutingOnlyGuests_user.graphql'
import { RoutingRequireAuthentication_user$key } from '../../generated/RoutingRequireAuthentication_user.graphql'
import { RoutingUserQuery } from '../../generated/RoutingUserQuery.graphql'

import { useStores } from '../../stores'
import { CommonStore } from '../../stores/commonStore'
import { classNames } from '../../utils/classNames'
import { AuthenticationLoggedOutPage } from '../pages/auth/AuthenticationLoggedOutPage'
import { AuthenticationLoginPage } from '../pages/auth/AuthenticationLoginPage'
import { AuthenticationLogoutPage } from '../pages/auth/AuthenticationLogoutPage'
import { SetPasswordAction } from '../pages/auth/SetPassword'

import { ErrorBoundary } from './ErrorBoundary'
import { Navigationbar } from './NavigationBar'
import { PageLoadingFallback } from './PageLoadingFallback'
import { Popup } from './Popup'
import styles from './Routing.scss'
import { MultiFactorAuthenticationPage } from '../pages/auth/MultiFactorAuthenticationPage'

const AuthenticationCmsRedirector = lazy(
  () => import('../pages/auth/AuthenticationCmsRedirector')
)
const AuthenticationForgotPasswordPage = lazy(
  () => import('../pages/auth/AuthenticationForgotPasswordPage')
)
const AuthenticationPasswordCompleteSsoPage = lazy(
  () => import('../pages/auth/AuthenticationPasswordCompleteSsoPage')
)
const AuthenticationPasswordExpiredPage = lazy(
  () => import('../pages/auth/AuthenticationPasswordExpiredPage')
)
const SelfActivatePage = lazy(() => import('../pages/auth/SelfActivatePage'))
const SetPassword = lazy(() => import('../pages/auth/SetPassword'))
const ActiveDuels = lazy(() => import('../pages/duels/ActiveDuels'))
const CompletedDuels = lazy(() => import('../pages/duels/CompletedDuels'))
const RankingMenu = lazy(() => import('../pages/duels/RankingMenu'))
const SelectTopic = lazy(() => import('../pages/learn/SelectTopic'))
const Stream = lazy(() => import('../pages/learn/Stream'))
const MainMenu = lazy(() => import('../pages/MainMenu'))
const OnboardingItemPage = lazy(
  () => import('../pages/onboarding/OnboardingItemPage')
)
const TimeLine = lazy(() => import('../pages/onboarding/TimeLine'))
const TopicCategories = lazy(
  () => import('../pages/onboarding/TopicCategories')
)

interface OnlyGuestsProps {
  children: ReactElement
  forceLogout?: boolean
  user?: RoutingOnlyGuests_user$key | null
}

interface RequireAuthenticationProps {
  children: ReactElement
  onboarding?: boolean
  powerapp?: boolean
  user?: RoutingRequireAuthentication_user$key | null
  skipMfa?: boolean
  forceActivateMfa?: boolean
}

export const Routing = observer(function Routing(): ReactElement {
  const { singleSignOn } = useEnvironment()
  const { commonStore } = useStores()
  const [privacyPolicyPopupShown, setPrivacyPolicyPopupShown] = useState(false)

  // TODO: Not supported in React Router v6. May not be needed anymore with new practice screen
  //       without a navigation bar?
  // const getUserConfirmation = useCallback(
  //   (_, callback): void => {
  //     commonStore.openPopup({ type: 'confirm-stop-practicing', callback })
  //   },
  //   [commonStore]
  // )

  const user = useQuery<RoutingUserQuery>(
    graphql`
      query RoutingUserQuery {
        me {
          firstLogin
          idp
          isOnboarding
          loginType
          isMfaAuthenticated
          hasMfaActivated
          ...NavigationBar_user
          ...RoutingAppRoutes_user
        }
      }
    `
  )

  useEffect(() => {
    if (
      !privacyPolicyPopupShown &&
      user.data?.me &&
      user.data.me.firstLogin &&
      user.data.me.loginType === 'SAML' &&
      !singleSignOn.idps.find((idp) => idp.name === user.data?.me?.idp)
        ?.isHybrid
    ) {
      commonStore.openPopup({
        type: 'privacy-policy',
      })
      setPrivacyPolicyPopupShown(true)
    }
  }, [user, commonStore, privacyPolicyPopupShown, singleSignOn.idps])

  const me = user.data?.me
  const showNavBar = me && !commonStore.navBarHidden
  const popup = commonStore.popups.find((p) =>
    CommonStore.canShowPopup(p, user.data?.me)
  )

  useEffect(() => {
    // Set commonStore to initialized as soon as user data is loaded.
    if (user.data) {
      commonStore.setAppInitialized(true)
    }
  }, [commonStore, user.data])

  // Prevent flash of guest route when logged in
  if (!user.data) {
    return <></>
  }

  return (
    <ErrorBoundary>
      <Router basename='/app'>
        <div
          className={classNames({
            [styles.routerWithNavigationBar]: showNavBar,
            [styles.routerWithItemNavigationBar]:
              commonStore.itemNavigationBarShown,
            [styles.onFire]: user.data?.me?.isOnboarding,
          })}
        >
          {showNavBar && (
            <div
              {...{
                inert: popup || commonStore.localPopupShown ? '' : undefined,
              }}
            >
              <Navigationbar user={me} />
            </div>
          )}

          {popup && (
            <Popup
              {...popup}
              key={
                // Force re-render when popup changes.
                popup.type + ('popupKey' in popup ? popup.popupKey : '')
              }
            />
          )}

          <div className={styles.route} {...{ inert: popup ? '' : undefined }}>
            {/* https://stackoverflow.com/questions/72720469/error-when-using-inert-attribute-with-typescript */}
            <Suspense fallback={<PageLoadingFallback />}>
              <AppRoutes user={user.data?.me ?? null} />
            </Suspense>
          </div>
        </div>
      </Router>
    </ErrorBoundary>
  )
})

function AppRoutes(props: {
  user: RoutingAppRoutes_user$key | null
}): ReactElement | null {
  const user = useFragment(
    graphql`
      fragment RoutingAppRoutes_user on AuthenticatedUser {
        hasMfaActivated
        isOnboarding
        isMfaAuthenticated
        ...RoutingOnlyGuests_user
        ...RoutingRequireAuthentication_user
        ...MultiFactorAuthenticationPage_user
      }
    `,
    props.user
  )

  return useRoutes([
    {
      path: '/login',
      element: (
        <OnlyGuests user={user}>
          <AuthenticationLoginPage />
        </OnlyGuests>
      ),
    },
    {
      path: '/login/cms',
      element: (
        <RequireAuthentication user={user}>
          <AuthenticationCmsRedirector />
        </RequireAuthentication>
      ),
    },
    {
      path: '/mfa',
      element: (
        <RequireAuthentication user={user} skipMfa={true}>
          {user && !user.isMfaAuthenticated ? (
            <MultiFactorAuthenticationPage user={user} />
          ) : (
            <Navigate to={'/'} />
          )}
        </RequireAuthentication>
      ),
    },
    {
      path: '/activateclientmfa',
      element: (
        <RequireAuthentication
          user={user}
          skipMfa={true}
          forceActivateMfa={true}
        >
          <MultiFactorAuthenticationPage user={user} />
        </RequireAuthentication>
      ),
    },
    { path: '/logout', element: <AuthenticationLogoutPage /> },
    {
      path: '/logged-out',
      element: <AuthenticationLoggedOutPage />,
    },
    {
      path: '/password/remind',
      element: (
        <OnlyGuests user={user}>
          <AuthenticationForgotPasswordPage />
        </OnlyGuests>
      ),
    },
    {
      path: '/password/expired/:email/:days/:token',
      element: (
        <OnlyGuests user={user}>
          <AuthenticationPasswordExpiredPage />
        </OnlyGuests>
      ),
    },
    {
      path: '/password/complete-sso/:idp/:email/:token',
      element: (
        <OnlyGuests forceLogout user={user}>
          <AuthenticationPasswordCompleteSsoPage />
        </OnlyGuests>
      ),
    },
    {
      path: '/password/accept-invitation/:token/:email?/:language?',
      element: (
        <OnlyGuests forceLogout user={user}>
          <SetPassword action={SetPasswordAction.Activation} />
        </OnlyGuests>
      ),
    },
    {
      path: '/password/self-activate',
      element: (
        <OnlyGuests forceLogout user={user}>
          <SelfActivatePage />
        </OnlyGuests>
      ),
    },
    {
      path: '/password/reset/:token/:email?/:language?',
      element: (
        <OnlyGuests forceLogout user={user}>
          <SetPassword action={SetPasswordAction.PasswordReset} />
        </OnlyGuests>
      ),
    },
    {
      path: '/',
      element: (
        <RequireAuthentication user={user}>
          {user ? (
            user?.isOnboarding ? (
              <TopicCategories />
            ) : (
              <SelectTopic />
            )
          ) : (
            <></>
          )}
        </RequireAuthentication>
      ),
    },
    {
      path: '/onboarding/:refId?',
      element: (
        <RequireAuthentication onboarding user={user}>
          <TimeLine />
        </RequireAuthentication>
      ),
    },
    {
      path: '/onboarding/item/:itemId?',
      element: (
        <RequireAuthentication onboarding user={user}>
          <OnboardingItemPage />
        </RequireAuthentication>
      ),
    },
    {
      path: '/practice',
      element: (
        <RequireAuthentication powerapp user={user}>
          <Stream />
        </RequireAuthentication>
      ),
    },
    {
      path: '/duels',
      element: (
        <RequireAuthentication powerapp user={user}>
          <ActiveDuels />
        </RequireAuthentication>
      ),
    },
    {
      path: '/duels/ranking',
      element: (
        <RequireAuthentication powerapp user={user}>
          <RankingMenu />
        </RequireAuthentication>
      ),
    },
    {
      path: '/duels/completed',
      element: (
        <RequireAuthentication powerapp user={user}>
          <CompletedDuels />
        </RequireAuthentication>
      ),
    },
    {
      path: '/settings',
      element: (
        <RequireAuthentication user={user}>
          <MainMenu />
        </RequireAuthentication>
      ),
    },
  ])
}

// The reason this codeblock is reused with a minor tweak is simple
// Like the RequireAuthentication but the user check is reversed to continue with logging in
// The purpose is to build the function component that redirect guests to another page than users.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function OnlyGuests(props: OnlyGuestsProps): ReactElement {
  const { authStore, commonStore } = useStores()

  const user = useFragment(
    graphql`
      fragment RoutingOnlyGuests_user on AuthenticatedUser {
        loginType
      }
    `,
    props.user || null
  )

  if (user && !props.forceLogout) {
    let url = '/'
    if (commonStore.postAuthRedirect) {
      url = commonStore.postAuthRedirect
      commonStore.setPostAuthRedirect(undefined)
    }

    return <Navigate to={url} />
  }

  if (user && props.forceLogout) {
    authStore.logout(user.loginType)
  }

  return props.children
}

function RequireAuthentication(
  props: RequireAuthenticationProps
): ReactElement {
  const { commonStore } = useStores()
  const location = useLocation()
  const { mfa } = useEnvironment()
  const target = commonStore.postAuthRedirect

  const user = useFragment(
    graphql`
      fragment RoutingRequireAuthentication_user on AuthenticatedUser {
        hasMfaActivated
        isOnboarding
        isMfaAuthenticated
      }
    `,
    props.user || null
  )

  if (
    props.skipMfa !== true &&
    mfa.enabled &&
    user &&
    ((mfa.app === 'OPTIONAL' &&
      (user.hasMfaActivated || props.forceActivateMfa) &&
      !user.isMfaAuthenticated) ||
      (mfa.app === 'REQUIRED' && !user.isMfaAuthenticated))
  ) {
    return (
      <Navigate
        to={
          '/mfa?activate=' + (!user.hasMfaActivated || props.forceActivateMfa)
        }
      />
    )
  }

  if (
    (props.onboarding && user && !user.isOnboarding) ||
    (props.powerapp && user && user.isOnboarding)
  ) {
    return <Navigate to='/' />
  }

  if (user) {
    if (target) {
      commonStore.setPostAuthRedirect(undefined)
      return <Navigate to={target} />
    }

    return props.children
  }

  commonStore.setPostAuthRedirect(location.pathname + location.search)

  return <Navigate to='/login' />
}
