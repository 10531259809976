import { faSpinner } from '@fortawesome/pro-regular-svg-icons/faSpinner'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { observer } from 'mobx-react'
import {
  ChangeEvent,
  ReactElement,
  SyntheticEvent,
  useCallback,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'
import { useMutation, useQuery } from 'relay-hooks/lib'
import { NewDuelPopupQuickDuelMutation } from '../../generated/NewDuelPopupQuickDuelMutation.graphql'
import {
  NewDuelPopupSearchOpponentsQuery,
  NewDuelPopupSearchOpponentsQueryResponse,
} from '../../generated/NewDuelPopupSearchOpponentsQuery.graphql'
import { NewDuelPopupSendInviteMutation } from '../../generated/NewDuelPopupSendInviteMutation.graphql'
import { useStores } from '../../stores'

import { NewDuelPopup as NewDuelPopupProps } from '../../stores/commonStore'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import { useDebounce } from '../../utils/hooks/useDebounce'
import { EmptyList } from '../common/EmptyList'
import { LoadingIndicator } from '../common/LoadingIndicator'
import { SecondaryButton } from '../common/SecondaryButton'
import { TertiaryButton } from '../common/TertiaryButton'

import styles from './NewDuelPopup.scss'
import { NewDuelSearchResult } from './NewDuelSearchResult'

const enum QuickDuelState {
  READY,
  LOADING,
  SEARCHING,
  ERROR,
}

type UserChallengeSearchResult =
  NewDuelPopupSearchOpponentsQueryResponse['possibleOpponents']['data'][0]

export const NewDuelPopup = observer(function NewDuelPopup(
  props: NewDuelPopupProps
): ReactElement {
  const { commonStore } = useStores()
  const { t } = useTranslation()

  const [, reduceSlotsAvailable] = useReducer((previous) => {
    const updated = previous - 1
    if (updated <= 0) {
      ;(props.onClose ?? commonStore.closePopup)()
    }

    return updated
  }, props.slotsAvailable)
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [quickDuelState, setQuickDuelState] = useState<QuickDuelState>(
    QuickDuelState.READY
  )

  const inputField = useRef<HTMLInputElement>(null)
  useDebounce(350, () => setDebouncedSearch(search), [search])

  const searchResults = useQuery<NewDuelPopupSearchOpponentsQuery>(
    graphql`
      query NewDuelPopupSearchOpponentsQuery($search: String) {
        possibleOpponents(search: $search) {
          data {
            id
            ...NewDuelSearchResult_result
          }
        }
      }
    `,
    { search: debouncedSearch }
  )

  const { duelsConnectionIds, duelRequestsConnectionIds } = props
  const [sendDuelRequest] = useMutation<NewDuelPopupSendInviteMutation>(graphql`
    mutation NewDuelPopupSendInviteMutation(
      $invitee: ID!
      $duelsConnectionIds: [ID!]!
      $duelRequestsConnectionIds: [ID!]!
    ) @raw_response_type {
      sendDuelInvite(invitee: $invitee) {
        duel
          @appendNode(
            connections: $duelsConnectionIds
            edgeTypeName: "DuelEdge"
          ) {
          id
          ...ActiveDuelSlot_duel
          ...DuelFinalizingPopup_item
          ...PlayDuelRound_duel
        }
        request
          @appendNode(
            connections: $duelRequestsConnectionIds
            edgeTypeName: "DuelRequestEdge"
          ) {
          id
          ...ActiveDuelSlot_request
          ...DuelRequestPopup_item
        }
        opponent {
          availability
          id
          invitedSince
        }
      }
    }
  `)

  const focusOnSearchBox = useCallback((): void => {
    if (inputField.current) {
      inputField.current.focus()
    }
  }, [])
  const onSearchChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setSearch(event.currentTarget.value)
    },
    []
  )
  const onSearchClear = useCallback(
    (event: SyntheticEvent): void => {
      event.preventDefault()

      setSearch('')
      setDebouncedSearch('')
      focusOnSearchBox()
    },
    [focusOnSearchBox]
  )
  const inviteUser = useCallback(
    (result: UserChallengeSearchResult): void => {
      sendDuelRequest({
        variables: {
          invitee: result.id,
          duelsConnectionIds,
          duelRequestsConnectionIds,
        },
        optimisticResponse: {
          sendDuelInvite: {
            duel: null,
            request: null,
            opponent: {
              availability: 'INVITED',
              id: result.id,
              invitedSince: null, // TODO: Can we set a better optimistic response?
            },
          },
        },
      }).then(reduceSlotsAvailable)
    },
    [duelRequestsConnectionIds, duelsConnectionIds, sendDuelRequest]
  )

  const [startQuickDuel] = useMutation<NewDuelPopupQuickDuelMutation>(
    graphql`
      mutation NewDuelPopupQuickDuelMutation {
        startQuickDuel {
          __typename
        }
      }
    `,
    { variables: {} }
  )
  const onRandomClicked = useCallback(() => {
    setQuickDuelState(QuickDuelState.LOADING)

    startQuickDuel()
      .then(() => setQuickDuelState(QuickDuelState.SEARCHING))
      .catch(() => setQuickDuelState(QuickDuelState.ERROR))
  }, [startQuickDuel])

  const renderPlaceholder = useCallback(
    (index: number) => <NewDuelSearchResult key={index} result={null} />,
    []
  )

  return (
    <div className={styles.content}>
      <h1 id='popup-label'>{t('searchDuel.title')}</h1>

      <div className={styles.searchBoxOuter} onClick={focusOnSearchBox}>
        <FontAwesomeIcon className={styles.icon} icon={faSearch} />

        <input
          aria-label={t('searchDuel.searchPlaceholder')}
          onChange={onSearchChanged}
          placeholder={t('searchDuel.searchPlaceholder')}
          ref={inputField}
          type='text'
          value={search}
        />

        <TertiaryButton
          aria-label={t('searchDuel.clearSearchField', {
            inputLabel: t('searchDuel.searchPlaceholder'),
          })}
          icon
          onClick={onSearchClear}
        >
          <FontAwesomeIcon icon={faTimes} />
        </TertiaryButton>
      </div>

      <ol className={styles.list}>
        {!searchResults.data ? (
          <LoadingIndicator />
        ) : searchResults.data.possibleOpponents.data.length === 0 ? (
          <>
            <p className={styles.noResults}>
              {t(
                search.trim().length > 0
                  ? 'searchDuel.emptyListSearch'
                  : 'searchDuel.emptyList'
              )}
            </p>

            <EmptyList renderItem={renderPlaceholder} />
          </>
        ) : (
          searchResults.data.possibleOpponents.data.map((result) => (
            <NewDuelSearchResult
              key={result.id}
              result={result}
              onInvite={() => inviteUser(result)}
            />
          ))
        )}
      </ol>

      <hr />

      <div className={styles.button}>
        <SecondaryButton
          disabled={
            quickDuelState === QuickDuelState.LOADING ||
            quickDuelState === QuickDuelState.SEARCHING ||
            quickDuelState === QuickDuelState.ERROR
          }
          onClick={onRandomClicked}
          onKeyPress={useEnterKeyHandler(onRandomClicked)}
        >
          {quickDuelState === QuickDuelState.LOADING ? (
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          ) : (
            t('menu.randomplayer')
          )}
        </SecondaryButton>

        {quickDuelState === QuickDuelState.SEARCHING && (
          <div className={styles.status}>{t('searchDuel.randomOpponent')}</div>
        )}
        {quickDuelState === QuickDuelState.ERROR && (
          <div className={`${styles.status} ${styles.error}`}>
            {t('menu.quickDuelFailed')}
          </div>
        )}
      </div>
    </div>
  )
})
