import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useStores } from '../../stores'
import { PrimaryButton } from './PrimaryButton'

import styles from './PrivacyPolicyPopup.scss'
import { useEnvironment } from '../../App'

export function PrivacyPolicyPopup(): ReactElement {
  const { t } = useTranslation()
  const { commonStore } = useStores()
  const environment = useEnvironment()

  const confirm = useCallback(() => commonStore.closePopup(), [commonStore])

  return (
    <div className={styles.content}>
      <h1 id='popup-label'>{t('popup.privacyPolicy.heading')}</h1>
      <p>{t('popup.privacyPolicy.explanation1')}</p>
      <p>
        <a href={environment.privacyPolicyUrl} rel='noreferrer' target='_blank'>
          {t('auth.field.viewPrivacyPolicy')}
        </a>
      </p>
      <PrimaryButton className={styles.button} onClick={confirm}>
        {t('popup.privacyPolicy.buttonText')}
      </PrimaryButton>
    </div>
  )
}
