import { ComponentProps, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { BackIcon } from '../common/BackIcon'

import { TertiaryButton } from '../common/TertiaryButton'

import styles from './AboutDuelsPopup.scss'

interface AboutDuelsPopupProps {
  setAttachment(
    children: ReactElement,
    props?: Partial<ComponentProps<typeof TertiaryButton>>
  ): void
}

export function AboutDuelsPopup(props: AboutDuelsPopupProps): ReactElement {
  const { t } = useTranslation()

  props.setAttachment(<BackIcon />)

  return (
    <div className={styles.content}>
      <h1 id='popup-label'>{t('menu.aboutDuels.heading')}</h1>

      <p>{t('menu.aboutDuels.explanation1')}</p>
      <p>{t('menu.aboutDuels.explanation2')}</p>
      <p className={styles.note}>{t('menu.aboutDuels.attention')}</p>
      <p>{t('menu.aboutDuels.explanation3')}</p>
    </div>
  )
}
