import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from './PrimaryButton'
import { FinishedOnboardingPopup as FinishedOnboardingPopupProps } from '../../stores/commonStore'

import styles from './FinishedOnboardingPopup.scss'
import { SecondaryButton } from './SecondaryButton'

export function FinishedOnboardingPopup(
  props: FinishedOnboardingPopupProps
): ReactElement {
  const { t } = useTranslation()

  return props.allowContinueToPowerApp ? (
    <div className={styles.content}>
      <h1 id='popup-label'>{t('popup.finishedOnboarding.proceedHeading')}</h1>
      <p>{t('popup.finishedOnboarding.proceedExplanation1')}</p>
      <p>{t('popup.finishedOnboarding.proceedExplanation2')}</p>
      <PrimaryButton
        className={styles.button}
        onClick={props.continueToPowerApp}
      >
        {t('popup.finishedOnboarding.proceedToPowerAppButtonText')}
      </PrimaryButton>
      <SecondaryButton className={styles.button} onClick={props.stayInOnfire}>
        {t('popup.finishedOnboarding.stayInOnfireButtonText')}
      </SecondaryButton>
    </div>
  ) : (
    <div className={styles.content}>
      <h1>{t('popup.finishedOnboarding.stayHeading')}</h1>
      <p>{t('popup.finishedOnboarding.stayExplanation')}</p>
      <PrimaryButton className={styles.button} onClick={props.stayInOnfire}>
        {t('popup.finishedOnboarding.closeButtonText')}
      </PrimaryButton>
    </div>
  )
}
