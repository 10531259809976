import { faImage } from '@fortawesome/pro-regular-svg-icons/faImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphql } from 'react-relay'
import {
  ChangeEvent,
  ComponentProps,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'relay-hooks'
import { AvatarCropPopupSubmitAvatarMutation } from '../../generated/AvatarCropPopupSubmitAvatarMutation.graphql'

import { useStores } from '../../stores'
import { AvatarCropPopup as AvatarCropPopupProps } from '../../stores/commonStore'
import { useEnterKeyHandler } from '../../utils/handleEnterKey'
import { BackIcon } from '../common/BackIcon'

import { PrimaryButton } from '../common/PrimaryButton'
import { SliderInput } from '../common/SliderInput'
import { TertiaryButton } from '../common/TertiaryButton'

import styles from './AvatarCropPopup.scss'

interface OwnProps {
  setAttachment(
    children: ReactElement,
    props?: Partial<ComponentProps<typeof TertiaryButton>>
  ): void
}

export function AvatarCropPopup(
  props: AvatarCropPopupProps & OwnProps
): ReactElement {
  const { commonStore } = useStores()
  const { t } = useTranslation()
  const cropper = useRef<AvatarEditor>(null)

  const [imageScalePercentage, setImageScalePercentage] = useState(100)

  props.setAttachment(<BackIcon />)

  const [uploadAvatar, uploadResult] =
    useMutation<AvatarCropPopupSubmitAvatarMutation>(
      graphql`
        mutation AvatarCropPopupSubmitAvatarMutation($file: Upload!) {
          changeAvatar(file: $file) {
            id
            profileImage
          }
        }
      `,
      { variables: { file: null } }
    )

  const onSubmit = useCallback((): void => {
    if (!cropper.current) {
      return
    }

    // Retrieve crop data
    cropper.current.getImageScaledToCanvas().toBlob((result) => {
      if (!result) {
        // Now what?
        return
      }

      // Create form data object
      const formData = new FormData()
      formData.append('avatar', result, props.filename)

      // Send to server
      uploadAvatar({ uploadables: { file: result } }).finally(
        commonStore.closePopup
      )
    }, props.mime)
  }, [commonStore, props.filename, props.mime, uploadAvatar])
  const onSubmitKeyPressHandler = useEnterKeyHandler(onSubmit)

  const onScaleChanged = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      setImageScalePercentage(parseInt(event.target.value, 10))
    },
    []
  )
  const formatPercentage = useCallback((value: number): string => {
    return value + '%'
  }, [])

  return (
    <div className={styles.content}>
      <h1 id='popup-label'>{t('profile.profilePictureTitle')}</h1>
      <p>{t('profile.pictureEdit')}</p>

      <div className={styles.outerCropper}>
        <AvatarEditor
          ref={cropper}
          image={props.image}
          borderRadius={100}
          scale={imageScalePercentage / 100}
        />

        <div className={styles.scaleSlider}>
          <div className={styles.size + ' ' + styles.small}>
            <FontAwesomeIcon icon={faImage} />
          </div>
          <SliderInput
            min={100}
            max={500}
            value={imageScalePercentage}
            onChange={onScaleChanged}
            valueToText={formatPercentage}
          />
          <div className={styles.size + ' ' + styles.large}>
            <FontAwesomeIcon icon={faImage} />
          </div>
        </div>
      </div>

      <PrimaryButton
        disabled={uploadResult.loading}
        onClick={onSubmit}
        onKeyPress={onSubmitKeyPressHandler}
        tabIndex={0}
      >
        {t('profile.picture-submit')}
      </PrimaryButton>
    </div>
  )
}
