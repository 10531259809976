import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useStores } from '../../stores'
import { PrimaryButton } from './PrimaryButton'
import { ComeBackLaterPopup as ComeBackLaterPopupProps } from '../../stores/commonStore'

import styles from './ComeBackLaterPopup.scss'

export function ComeBackLaterPopup(
  props: ComeBackLaterPopupProps
): ReactElement {
  const { t } = useTranslation()
  const { commonStore } = useStores()

  const confirm = useCallback((): void => {
    props.onConfirm().then(() => {
      commonStore.closePopup()
    })
  }, [commonStore, props])

  return (
    <div className={styles.content}>
      <h1 id='popup-label'>{t('popup.comeBackLater.heading')}</h1>
      <p>{t('popup.comeBackLater.explanation1')}</p>
      <p>{t('popup.comeBackLater.explanation2')}</p>
      <p className={styles.date}>{props.comeBackDate}</p>
      <PrimaryButton className={styles.button} onClick={confirm}>
        {t('popup.comeBackLater.buttonText')}
      </PrimaryButton>
    </div>
  )
}
